<template>
  <div>
    <slot name="activator" :on="showDialog"></slot>
    <!-- <div href="javascript:;" @click="showDialog()"> -->
    <slot></slot>
    <v-dialog v-model="dialog" max-width="700px">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title>
            {{ dataDetail.contact.first_name }}
            {{ dataDetail.contact.last_name }}
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn
            icon
            @click="
              redirect('Sales.Contact.Edit', { id: dataDetail.contact.id })
            "
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon @click.stop="dialog = !dialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- <v-divider class="mb-2"></v-divider> -->
        <v-img
          src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
          height="260px"
          class="white--text mb-4 blue-grey lighten-1"
          gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
        >
          <v-row class="fill-height">
            <v-col
              class="d-flex align-center justify-center fill-height flex-column"
            >
              <v-avatar size="80" color="primary">
                <v-img
                  class="elevation-6"
                  alt
                  :src="resourceUrl(dataDetail.contact.picture)"
                ></v-img>
              </v-avatar>
              <v-card-title>
                {{ dataDetail.contact.first_name }}
                {{ dataDetail.contact.last_name }}
              </v-card-title>
              <v-card-subtitle>
                {{ dataDetail.contact.company_name }}
              </v-card-subtitle>
            </v-col>
          </v-row>
        </v-img>
        <v-card-text class="pb-0 mb-2">
          <v-row>
            <v-col>
              <v-row class="my-0">
                <v-col cols="12" md="4" class="py-0">
                  <b>No.:</b>
                </v-col>
                <v-col cols="12" md="8" class="py-0">
                  {{ dataDetail.contact.no_ }}
                </v-col>
              </v-row>
              <v-row class="my-0">
                <v-col cols="12" md="4" class="py-0">
                  <b>First Name:</b>
                </v-col>
                <v-col cols="12" md="8" class="py-0">
                  {{ dataDetail.contact.first_name }}
                </v-col>
              </v-row>
              <v-row class="my-0">
                <v-col cols="12" md="4" class="py-0">
                  <b>Last Name:</b>
                </v-col>
                <v-col cols="12" md="8" class="py-0">
                  {{ dataDetail.contact.last_name }}
                </v-col>
              </v-row>
              <v-row class="my-0">
                <v-col cols="12" md="4" class="py-0">
                  <b>Address:</b>
                </v-col>
                <v-col cols="12" md="8" class="py-0">
                  {{ dataDetail.contact.address }}
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row class="my-0">
                <v-col cols="12" md="4" class="py-0">
                  <b>Email:</b>
                </v-col>
                <v-col cols="12" md="8" class="py-0">
                  {{ dataDetail.contact.email }}
                </v-col>
              </v-row>
              <v-row class="my-0">
                <v-col cols="12" md="4" class="py-0">
                  <b>Phone No:</b>
                </v-col>
                <v-col cols="12" md="8" class="py-0">
                  {{ dataDetail.contact.phone_no }}
                </v-col>
              </v-row>
              <v-row class="my-0">
                <v-col cols="12" md="4" class="py-0">
                  <b>Mobile No.:</b>
                </v-col>
                <v-col cols="12" md="8" class="py-0">
                  {{ dataDetail.contact.mobile_phone_no }}
                </v-col>
              </v-row>
              <v-row class="my-0">
                <v-col cols="12" md="4" class="py-0">
                  <b>Office No.:</b>
                </v-col>
                <v-col cols="12" md="8" class="py-0">
                  {{ dataDetail.contact.office_no }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="mb-2"></v-divider>

        <v-card-actions class="align-end">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="
              redirect('Sales.Contact.Edit', { id: dataDetail.contact.id })
            "
            >Edit</v-btn
          >
          <v-btn @click.stop="dialog = !dialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialog: null,
      dataDetail: {
        contact: {},
      },
      showDetail: false,
    };
  },
  watch: {
    dialog(n, o) {
      if (o == true && n == false) {
        this.$emit("callback");
      }
    },
  },
  methods: {
    refreshDetail() {
      this.getDetail(true);
    },

    showDialog() {
      this.dialog = true;
      this.getDetail(true);
    },

    getDetail() {
      this.dialog = false;
      this.showLoadingOverlay(true);
      this.dataDetail.contact = {};
      var params = {
        id: this.id,
      };
      this.$axios
        .get("contact/data-detail", {
          params: params,
          cacheConfig: true,
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.dataDetail.contact = resData.data.contact;
          } else {
            this.showAlert(resData.status, resData.message);
          }

          this.dialog = true;
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    edit() {
      if (typeof this.dataDetail.contact.id !== "undefined") {
        this.redirect("Sales.Contact.Edit", { id: this.dataDetail.contact.id });
      } else {
        this.showAlert("warning", "Please select contact");
      }
    },
  },
};
</script>